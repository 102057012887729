<template>
<div>
    <div class="col-md-12">
        <div class="row" style="margin-top:20px">
            <div class="col-md-6">
                <h3 class="page-header">
                <i class="bx bx-send animated bounceInDown show-info"></i>
                New Order
                </h3>
            </div>
        </div>
    </div>
    <div class="flex-row">
        <div class="col-md-12">
            <div class="panel panel-cascade">
                <div class="info-box bg-primary text-white">
                    <div class="info-icon bg-warning-dark">
                        <i class="fa fa fa-user fa-2x"></i>
                    </div>
                    <div class="info-details">
                        <h4>Order Details</h4>
                    </div>
                </div>
                <div class="panel-body" >
                    <div class="form-horizontal cascde-forms">
                        <div class="form-group flex-row">
                            <label class="col-lg-4 col-md-3 control-label">Select Customer<span style="color:red">*</span> </label>
                            <div class="col-lg-8 col-md-9">
                                <!-- <select v-model="customertype" class="form-control form-cascade-control input-small">
                                    <option value="1">New Customer</option>
                                    <option value="2">Existing Customer</option>
                                </select> -->
                                <div class="flex-around-row gap-20">
                                    <div>
                                        <label for="cust">New Customer</label>
                                        <input style="margin-left:10px ;" type="radio" name="customertype" value="1" id="cust" v-model="customertype">
                                    </div>
                                    <div>
                                        <label for="cust2">Existing Customer</label>
                                        <input style="margin-left:10px ;" type="radio" name="customertype" value="2" id="cust2" v-model="customertype">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group flex-row" v-if="customertype==1">
                            <label class="col-lg-4 col-md-3 control-label">Customer Name<span style="color:red">*</span> </label>
                            <div class="col-lg-8 col-md-9" :class="{ 'form-group--error': $v.customername.$error }">
                                <input class="form-control form-cascade-control input-small" v-model="$v.customername.$model" type="text" />
                                <span class="error" v-if="!$v.customername.minLength">Name should be greater than 2</span>
                            </div>
                        </div>
                        <div class="form-group flex-row" v-if="customertype==1">
                            <label class="col-lg-4 col-md-3 control-label">Customer Mobile No.<span style="color:red">*</span> </label>
                            <div class="col-lg-8 col-md-9" :class="{ 'form-group--error': $v.mobileno.$error }">
                                <input class="form-control form-cascade-control input-small" v-model="$v.mobileno.$model" type="number" />
                                <!-- <span class="error" v-if="!$v.newmobile.mobile.required">Field is required</span> -->
                                <span class="error" v-if="!$v.mobileno.maxLength">Mobile No. should not exceed {{$v.mobileno.$params.maxLength.max}} letters.</span>
                                <span class="error" v-if="!$v.mobileno.minLength">Mobile No. should of minimum 10 digit</span>
                            </div>
                        </div>
                        <div class="form-group flex-row" v-if="customertype==1">
                            <label class="col-lg-4 col-md-3 control-label">Customer Address<span style="color:red">*</span> </label>
                            <div class="col-lg-8 col-md-9">
                                <input class="form-control form-cascade-control input-small" v-model="customeraddress" type="text" />
                                </div>
                        </div>
                        <div class="form-group flex-row" v-if="customertype==2">
                            <label class="col-lg-4 col-md-3 control-label">Customer VSDIGI ID<span style="color:red">*</span> </label>
                            <div class="col-lg-8 col-md-9">
                                <input class="form-control form-cascade-control input-small" v-model="existcustomername" type="text" value="" @input="getUser()"/>
                                <!-- <p v-if="founduser!=null">{{ founduser.name}}</p> -->
                                <div class="form-group row" v-if="founduser!=null">
                                    <div class="col-md-2" style="margin-top:5px;" v-if="founduser!=null">
                                        <div class="referal-text" ><strong>{{ founduser.name }}</strong></div>
                                    </div>
                                    <div class="col-md-4" style="margin-top:5px;" v-if="founduser!=null">
                                        <display-mobiles :mobiles="founduser.mobiles" class="referal-text"></display-mobiles>
                                    </div> <!-- input-group.// -->					    
                                    <div class="col-md-6" style="margin-top:5px;" v-if="founduser!=null">
                                        <display-first-address :addresses="founduser.addressess" class="referal-text"></display-first-address>
                                    </div> <!-- input-group.// -->	
                                </div>
                                <p v-if="usernotfound" style="color:red">Customer Not Found</p>
                            </div>
                        </div>
                        <div class="form-group flex-row" v-if="customertype==1">
                            <label class="col-lg-4 col-md-3 control-label">Introducer VSDIGI ID<span style="color:red">*</span> </label>
                            <div class="col-lg-8 col-md-9">
                                <input class="form-control form-cascade-control input-small" v-model="advisor" type="text" value="" @input="getAdvisor()"/>
                                <p v-if="usernotfound" style="color:red">Introducer Not Found</p>
                                <div class="form-group row" v-if="advisorfound!=null">
                                    <div class="col-md-2" style="margin-top:5px;" v-if="advisorfound!=null">
                                        <div class="referal-text" ><strong>{{ advisorfound.name }}</strong></div>
                                    </div>
                                    <div class="col-md-4" style="margin-top:5px;" v-if="advisorfound!=null">
                                        <display-mobiles :mobiles="advisorfound.mobiles" class="referal-text"></display-mobiles>
                                    </div> <!-- input-group.// -->					    
                                    <div class="col-md-6" style="margin-top:5px;" v-if="advisorfound!=null">
                                        <display-first-address :addresses="advisorfound.addressess" class="referal-text"></display-first-address>
                                    </div> <!-- input-group.// -->	
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-lg-4 col-md-3">
                                <label for="subcategory" class="col-form-label control-label">Select Category</label>
                            </div>
                            <div class="col-lg-8 col-md-9">
                                <select class="form-control form-cascade-control input-small" v-model="parentid" ref="myInput">
                                <option selected>----Select-----</option>
                                <option v-for="item in parentcategory" v-bind:value="item.id" :key="item.id">{{item.name}}</option>
                                </select>
                                <span class="invalid-feedback" role="alert">
                                    <strong></strong>
                                </span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-lg-4 col-md-3">
                                <label for="subcategory" class="col-form-label control-label">Select Product</label>
                            </div>
                            <div class="col-lg-8 col-md-9">
                                <select @change="fetchbrands()" class="form-control form-cascade-control input-small" v-model="category_id">
                                <option selected>----Select-----</option>
                                <option v-for="item in subcategory" v-bind:value="item.id" :key="item.id">{{item.name}}</option>
                                </select>
                                <span class="invalid-feedback" role="alert">
                                    <strong></strong>
                                </span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class=" col-lg-4 col-md-3">
                                <label for="subcategory" class="col-form-label control-label">Company</label>
                            </div>
                            <div class="col-lg-8 col-md-9">
                                <select v-model="brandid" @change="fetchproducts()" class="form-control form-cascade-control input-small" >
                                    <option value="0">---Select---</option>
                                    <option v-for="filter in brands" :key="filter.id" :value="filter.id">{{filter.value}}</option>
                                </select>
                                <span class="invalid-feedback" role="alert">
                                    <strong></strong>
                                </span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-lg-4 col-md-3">
                                <label for="firstname" class="col-form-label control-label">Model Name<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                            </div>
                            <div class="col-lg-8 col-md-9">
                                <select v-model="productid" class="form-control form-cascade-control input-small" >
                                    <option v-for="filter in products" :key="filter.id" :value="filter.id">{{filter.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group flex-row">
                            <label class="col-lg-4 col-md-3 control-label">Remark</label>
                            <div class="col-lg-8 col-md-9">
                                <textarea class="form-control form-cascade-control input-small" cols="20" v-model="remark" rows="2">
                                </textarea>
                            </div>
                        </div>
                        
                </div>
            </div>
        </div>
        </div>
        <div class=" col-md-12 text-center">
            <input type="submit" value="Submit" style="width:20%" class="btn bg-primary text-white btn-md" @click="submit()">
        </div>
        
    </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
// import ReferralSelector from '../../components/user/ReferralSelector.vue'
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue'
import DisplayFirstAddress from '../../components/address/DisplayFirstAddress.vue'
import { maxLength,minLength } from 'vuelidate/lib/validators'
export default{
    components:{DisplayMobiles,DisplayFirstAddress},
    data(){
        return{
            followedid:'',
            requesttype:4,
            remark:'',
            leadtype:0,
            id:0,
            leaddate:'',
            checkduplicate:false,
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            },
            usernotfound:false,
            customername:'',
            existcustomername:'',
            founduser:[],
            brandid:0,
            products:[],
            parentid:0,
            category_id:0,
            brands:[],
            productid:0,
            advisor:'',
            advisorfound:[],
            customertype:0,
            customeraddress:'',
            mobileno:''
        }
    },
    validations:{
        mobileno:{
            minLength:minLength(10),
            maxLength:maxLength(10)
        },
        customername:{
            minLength:minLength(2)
        }
    },
    computed:{
        ...mapGetters(['employees','referraluser','loggedinuser','categories']),
        parentcategory(){
            if(this.categories!=null){
                return this.categories.filter(block=>block.parent_id==0)
            }
            return [];
        },
        subcategory(){
            if(this.categories!=null){
                return this.categories.filter(block=>block.parent_id==this.parentid)
            }
            return [];
        },
        /*brands(){
            let attribute = this.attributes.find(block=>block.id==2)
            return attribute
        },*/
        predefinedvalues(){
            if(this.brands!=null && this.brands.predefinedvalues!=null){
                return this.brands.predefinedvalues
            }
            return [];
        },
        getval(value){
            return value
        },

        filters(){
            return this.categories.filter(item => item.parent_id != 0 && item.parent_id != null)
        },
        selectedproduct(){
            if(this.products!=null){
                return  this.products.find(block=>block.id==this.productid)
            }
            return [];
        },
    },
    mounted(){
        this.refresh();
    },
    methods:{
        getUser(){
            let param={generatedid:this.existcustomername}
            this.$http.post('api/user/getuserbygeneratedid',param)
            .then((response) => this.processGetUSerResponse(response.data))
            .catch((err) => {
                console.log('', err)
            });
        },
        processGetUSerResponse(data){
            if(data!=null){
                this.founduser=data
                // this.usernotfound=false
            }else{
                // this.usernotfound=true
            }
        },
        getAdvisor(){
            let param={generatedid:this.advisor}
            this.$http.post('api/user/getuserbygeneratedid',param)
            .then((response) => this.processAdvisorUSerResponse(response.data))
            .catch((err) => {
                console.log('', err)
            });
        },
        processAdvisorUSerResponse(data){
            if(data!=null){
                this.advisorfound=data
                // this.usernotfound=false
            }else{
                // this.usernotfound=true
            }
        },
        mobileduplicate(){
            let param={mobileno:this.mobileno}
            this.$http.post('api/leads/check/mobile',param) 
            .then((response) => this.processCheckResponse(response.data))
            .catch((err) => {
                console.log('', err)
            });
        },
        processCheckResponse(data){
            if(data==true){
                this.checkduplicate=true
            }else{
                this.checkduplicate=false
            }
        },
        refresh(){
            this.$store.dispatch('fetchemployees')     
            this.$store.dispatch("fetchproducts")
            this.$store.dispatch('fetchcategories')
            this.$store.dispatch('fetchattributes')                                                             
        },
        initializedata(){
            this.followedid=''
            this.customername=''
            this.requesttype=0
            this.remark=''
            this.leadtype=0
            this.leaddate=''
            this.founduser=[]
            this.advisorfound=[]
            this.advisor=''
            this.existcustomername=''
            this.mobileno='',
            this.customeraddress=''
            this.products=[]
            this.parentid=0
            this.brandid=0
            this.category_id=0
        },
        submit(){
            let advisorcheck=false
            if(this.customertype==1){
                advisorcheck=true
                if(this.advisorfound.id>0){
                    advisorcheck=false
                }
            }
            if(this.checkduplicate==false && !this.$v.$invalid && advisorcheck!=true && (this.customername!='' || this.founduser.id>0) && this.productid!='' && this.productid>0){
                this.$notify({text:'Submitted',type:'warn'})
                let param = {id:this.id,followedid:this.followedid,
                    customerid:this.founduser?.id??0,
                    advisorid:this.advisorfound?.id??0,
                    requesttype:this.requesttype,
                    remark:this.remark,
                    isdispose:0,leaddate:this.leaddate,
                    productid:this.productid,
                    customername:this.customername,
                    customeraddress:this.customeraddress,
                    mobileno:this.mobileno
                }
                this.$store.commit('assignloadingstatus',1)
                this.$http.post('api/leads/createupdate',param)
                .then((response) => this.processLeadResponse(response.data))
                .catch((err) => {
                    console.log('', err)
                });
            }else{
                this.$notify({text:'Please Avoid the Error Mention in the Form',type:'error'})
            }
        },
        processLeadResponse(){
            this.initializedata();
            this.$notify({text:'Submitted Successfully',type:'success'})
            this.$store.commit('assignloadingstatus',0)
        },
        
        fetchbrands(){
            
            let param = {parentcategoryid:this.category_id,attributeid:2}
            window.axios.post('api/attribute/values/fetchbycategory',param)
                .then(response=>this.processAvailableBrands(response.data))
                .catch(error=>console.log(error));
        },
        processAvailableBrands(data){
            this.brands = data
        },
        fetchproducts(){
            
            let param = {parentcategoryid:this.category_id,attributeid:2,attributevalueids:this.brandid}
            window.axios.post('api/products/fetchbyfilters',param)
                .then(response=>this.processProductsResponse(response.data))
                .catch(error=>console.log(error));
        },
        processProductsResponse(data){
            this.products = data
        },
    }
}
</script>
<style>
.flex-row{
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    flex-wrap: wrap;
}
</style>